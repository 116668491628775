export function useLibrariesNavigation() {
  const selectedCookie = useCookie('library-selected-cookie')
  const selected = useState('library-selected', () => selectedCookie.value)

  const localePath = useLocalePath()
  const route = useRoute()
  const routeName = computed(() => `${route.name as string}`.split('___')[0])
  const isOnLibraryPage = computed(() => routeName.value === 'pro-libraries-libraryId')

  watch(selected, (value) => {
    selectedCookie.value = value
  })

  const toLibrary = computed(() => {
    if (selected.value && !isOnLibraryPage.value)
      return localePath({ name: 'pro-libraries-libraryId', params: { libraryId: selected.value } })

    return localePath('pro-libraries')
  })

  const { track } = useAnalytic()
  const trackToLibrary = (from: string) => {
    if (selected.value && !isOnLibraryPage.value)
      track('goto-library-id', { from, libraryId: selected.value })
    else
      track('goto-libraries', { from })
  }

  const newFeature = computed(() => !selected.value)

  return {
    selected,
    toLibrary,
    trackToLibrary,
    newFeature,
  }
}

interface Libraries {
  organization: AppGuide.Libraries.ListLibrary[]
  others: AppGuide.Libraries.ListLibrary[]
}

export function useProLibraries() {
  const { locale } = useI18n()
  const pending = useState('pro-libraries-pending', () => false)
  const fetched = useState('pro-libraries-fetched', () => false)
  const _libraries = useState<Libraries>('pro-libraries', () => ({
    organization: [],
    others: [],
  }))

  async function fetchLibraries() {
    if (pending.value || fetched.value)
      return

    pending.value = true

    try {
      const response = await api.libraries.library.list()

      if (!response.success)
        throw new ApiError(response)

      _libraries.value = response.data
      fetched.value = true
    }
    catch (err) {
      console.error(err)
    }
    finally {
      pending.value = false
    }
  }

  const libraries = computed(() => {
    const organization = _libraries.value.organization
      .filter(lib => lib.library_language === locale.value)
    const others = _libraries.value.others
      .filter(lib => lib.library_language === locale.value)

    return [
      { type: 'organization', libraries: organization },
      { type: 'others', libraries: others.filter(lib => lib.organization.id !== 11) },
      { type: 'appguide', libraries: others.filter(lib => lib.organization.id === 11) },
    ].filter((x: any) => x.libraries.length > 0) as {
      type: 'organization' | 'others' | 'appguide'
      libraries: AppGuide.Libraries.ListLibrary[]
    }[]
  })

  return {
    pending,
    fetched,
    libraries,
    _libraries,
    fetchLibraries,
  }
}
